/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {

        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages

                // Navigation
                var navMenu = $('#nav.main__nav');
                var navOpen = $('#nav__open');
                var navClose = $('#nav__close');
                var ulMenu = $('#menu-main');
                ulMenu.attr('aria-haspopup', 'true');
                ulMenu.attr('tabindex', '-1');

                $('body').focusin(function (e) {
                    if ($(navMenu)[0] !== undefined && !$.contains($(navMenu)[0], document.activeElement)) {
                        navClose.trigger('click');
                    }
                });

                // keyboard navigation
                navMenu.on('keydown', function (event) {
                    // keyCode -> http://www.javascripter.net/faq/keycodes.htm
                    // 27 = esc
                    if (event.keyCode === 27) {
                        navClose.trigger('click');
                    }
                });

                // open navigation
                navOpen.click(
                    function () {
                        navOpen.attr('aria-expanded', navOpen.attr('aria-expanded') === 'false' ? 'true' : 'false');
                        navClose.attr('aria-expanded', navClose.attr('aria-expanded') === 'false' ? 'true' : 'false');
                        ulMenu.attr('aria-expanded', navOpen.attr('aria-expanded') === 'false' ? 'true' : 'false');
                        navMenu.addClass('open');
                        $('html, body').addClass('main__nav-opened');
                    }
                );

                // close navigation
                navClose.click(
                    function () {
                        navOpen.attr('aria-expanded', navOpen.attr('aria-expanded') === 'false' ? 'true' : 'false');
                        navClose.attr('aria-expanded', navClose.attr('aria-expanded') === 'false' ? 'true' : 'false');
                        ulMenu.attr('aria-expanded', navOpen.attr('aria-expanded') === 'false' ? 'true' : 'false');
                        navMenu.removeClass('open');
                        $('html, body').removeClass('main__nav-opened');
                    }
                );

                // toggle sub-menu
                var menuMainItems = document.querySelectorAll('.main_menu li.menu-item-has-children');

                function setAria() {
                    if ($(window).width() > 820) {

                    }

                    menuMainItems.forEach(function (item) {
                        var firstA = item.querySelector('a');
                        if (firstA.querySelector('.arrowLine') == undefined) {
                            firstA.insertAdjacentHTML('beforeend', '<span class="arrowLine">></span>');
                        }
                    });
                }
                setAria();
                $(window).resize(function () { setAria(); });

                Array.prototype.forEach.call(menuMainItems, function (el, i) {
                    el.querySelector('a').addEventListener("click", function (event) {

                        // tolgo class open da tutti gli a
                        menuMainItems.forEach(function (item) {
                            item.classList.remove('open');
                        });

                        if (this.parentNode.classList.contains('open')) {
                            this.parentNode.classList.remove('open');
                        } else {
                            this.parentNode.classList.add('open');
                        }
                        event.preventDefault();

                        return false;
                    });

                    // focus
                    el.addEventListener("focusin", function (event) {
                        this.classList.add('open');
                    });
                    el.addEventListener("focusout", function (event) {
                        this.classList.remove('open');
                    });
                });

                var menuHeaderItems = document.querySelectorAll('.header_menu li.menu-item-has-children');
                Array.prototype.forEach.call(menuHeaderItems, function (el, i) {

                    var openMenuFunction = function (event) {
                        event.stopPropagation();
                        this.querySelector('.sub-menu').classList.add('open');
                        this.closest('div').classList.add('menuOpen');
                    };

                    var closeMenuFunction = function (event) {
                        event.stopPropagation();
                        this.querySelector('.sub-menu').classList.remove('open');
                        this.closest('div').classList.remove('menuOpen');
                    };

                    // focus
                    el.addEventListener("focusin", openMenuFunction, false);
                    el.addEventListener("focusout", closeMenuFunction, false);

                    // mouse
                    el.addEventListener("mouseover", openMenuFunction, false);
                    el.addEventListener("mouseleave", closeMenuFunction, false);


                    // click
                    el.querySelector('a').addEventListener("click", function (event) {
                        //this.parentNode.querySelector('.sub-menu').classList.toggle('open');
                        //this.closest('div').classList.toggle('menuOpen');

                        event.preventDefault();
                        return false;
                    });
                });

                // AOS - animate on scroll
                // setting
                AOS.init({
                    offset: 120,
                    once: true,
                });

                document.querySelectorAll('img')
                    .forEach(function (img) {
                        img.addEventListener('load', function () {
                            AOS.refresh();
                        });
                    });


                var gallerySliderSingle = $('.gallery--single');

                gallerySliderSingle.slick({
                    arrows: false,
                    autoplay: false,
                    autoplaySpeed: 4000,
                    infinite: true,
                    pauseOnHover: true,
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    rows: 0, // remove div https://github.com/kenwheeler/slick/issues/3110
                });


                // Magnific Popup
                // Image
                var magPopImg = $('.magnificpopup');
                magPopImg.magnificPopup({
                    type: 'image',
                    image: {
                        titleSrc:
                            function (item) {
                                return item.el.find('img').attr('alt');
                            }
                    }
                });

                // Video
                var magPopVideo = $('.magnificpopup--video');
                magPopVideo.magnificPopup({
                    type: 'iframe',
                    iframe: {
                        markup:
                            '<div class="mfp-iframe-scaler">' +
                            '<div class="mfp-close"></div>' +
                            '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
                            '</div>', // HTML markup of popup, `mfp-close` will be replaced by the close button
                        patterns: {
                            youtube: {
                                index: 'youtube.com/', // String that detects type of video (in this case YouTube). Simply via url.indexOf(index).

                                id: 'embed/', // String that splits URL in a two parts, second part should be %id%
                                // Or null - full URL will be returned
                                // Or a function that should return %id%, for example:
                                // id: function(url) { return 'parsed id'; }

                                src: '//www.youtube.com/embed/%id%' // URL that will be set as a source for iframe.
                            }
                        },
                    }
                });

                // Gallery
                var magPopGallery = $('.magnificpopup--gallery');
                magPopGallery.each(function () { // the containers for all your galleries

                    $(this).find('a').magnificPopup({
                        //delegate: 'a', // the selector for gallery item
                        type: 'image',
                        closeBtnInside: true,
                        closeOnBgClick: false,
                        gallery: {
                            enabled: true
                        },
                        image: {
                            titleSrc:
                                function (item) {
                                    return item.el.find('img').attr('alt');
                                },
                        },
                    });

                });

                // Gallery
                var wysiwygGallery = $('.text--wysiwyg .gallery');
                wysiwygGallery.each(function () { // the containers for all your galleries

                    $(this).find('a').magnificPopup({
                        //delegate: 'a', // the selector for gallery item
                        type: 'image',
                        gallery: {
                            enabled: true
                        },
                        image: {
                            titleSrc:
                                function (item) {
                                    return item.el.find('img').attr('alt');
                                },
                        },
                    });

                });

                // Arrow to top
                var top_arrow = $('.top_arrow');
                var htmlBody = $('html, body');
                window.addEventListener('scroll', function () {
                    var distanceTop = htmlBody.scrollTop();
                    if (distanceTop > 100) {
                        top_arrow.fadeIn();
                    } else {
                        top_arrow.fadeOut();
                    }
                });

                // aggiunta classe a header fixed quando si scrolla la pagina
                var header = document.querySelector("header");
                var mainMenu = document.querySelector('#nav');
                window.addEventListener("scroll", function () {
                    var stickyTop = window.scrollY;

                    if (stickyTop > 50) {
                        header.classList.add('sticky');
                    } else {
                        if (header.classList.contains('sticky')) {
                            header.classList.remove('sticky');
                        }
                    }
                });
                // controllo altezza header anche nel document.ready
                var scrollY = window.scrollY;
                if (scrollY > 50) {
                    header.classList.add('sticky');
                }

                // accordion tabs dettaglio modelli o prodotti
                var tabsDettaglio = document.querySelectorAll('.detail_accordion_row');

                if (tabsDettaglio) {
                    tabsDettaglio.forEach(function (item) {
                        item.addEventListener('click', function (e) {
                            var parentDiv = e.target.closest('div');
                            if (parentDiv) {
                                parentDiv.classList.toggle('active');
                            }
                        });
                    });
                }

                // funzione per calcolo rata finanziamento
                function calcolaRata(importoAuto, anticipo, tan, totRate) {
                    // verifico parametri passati
                    if (
                        importoAuto <= 0 || isNaN(importoAuto) || tan <= 0 || isNaN(tan)
                    ) {
                        return 0;
                    }

                    var rataFinanziamento = 0.00;
                    var period = 12; // numero rate annuali
                    var numeroAnniPrestito = totRate / period;
                    var importoDaFinanziare = 0;

                    if (anticipo) {
                        importoDaFinanziare = Number(importoAuto) - Number(anticipo);
                    } else {
                        importoDaFinanziare = Number(importoAuto);
                    }

                    var N = totRate;
                    var I = (Number(tan) / 100) / period;
                    var v = Math.pow((1 + I), N);
                    var t = (I * v) / (v - 1);
                    rataFinanziamento = Number(importoDaFinanziare) * t;

                    return Number(rataFinanziamento).toFixed(2).replace('.', ',');
                }

                // inizializzo calcolo rata
                function initializeCalcoloRata() {

                    // popolo il popup con i dati del finanziamento
                    var calcolaRataElement = document.querySelectorAll('.calcolaRata');
                    //console.log(calcolaRataElement);
                    if (calcolaRataElement) {
                        calcolaRataElement.forEach(function (item) {

                            var myElement = $(item);

                            // popup calcola rata
                            myElement.magnificPopup({
                                type: 'inline',
                                midClick: true,
                                mainClass: 'calcolaRataPopup',
                                closeBtnInside: false,
                                closeOnBgClick: false,
                                callbacks: {
                                    open: function (e) {
                                        // Will fire when this exact popup is opened
                                        // this - is Magnific Popup object

                                        // svuoto i vecchi valori
                                        document.querySelector('#calcolaRataPopup .importoFinanziamento').innerHTML = '';
                                        document.querySelector('#calcolaRataPopup .importoFinanziamentoValue').value = 0;
                                        document.querySelector('.rataFinale').innerHTML = '€ 0';

                                        var stepRate3 = document.querySelectorAll('.stepRata');
                                        stepRate3.forEach(function (item) {
                                            if (item.classList.contains('selected')) {
                                                item.classList.remove('selected');
                                            }
                                        });
                                    },
                                    close: function () {
                                        // Will fire when popup is closed

                                        // reset campo anticipo
                                        document.querySelector('#calcolaRataPopup .importoAnticipo').value = 0;
                                    }
                                    // e.t.c.
                                }
                            });

                            item.addEventListener('click', function (e) {
                                var importoFinanziamento = e.target.querySelector('.importoRataPopup').value;
                                var importoFinanziamentoFormatted = e.target.querySelector('.importoRataFormattedPopup').value;

                                // valorizzo i campi del popup
                                document.querySelector('#calcolaRataPopup .importoFinanziamento').innerHTML = importoFinanziamentoFormatted;
                                document.querySelector('#calcolaRataPopup .importoFinanziamentoValue').value = importoFinanziamento;
                            });
                        });
                    }

                    // variabili calcolo rata
                    var tanFinanziamento = document.querySelector('.tanFinanziamento').value;

                    // inizializzo evento click sugli step delle rate
                    var stepRate = document.querySelectorAll('.stepRata');
                    if (stepRate) {
                        stepRate.forEach(function (item) {
                            item.addEventListener('click', function (e) {
                                var rataSelected = e.target.innerHTML;

                                // valorizzo campo hidden rataSelected
                                document.querySelector('.rataSelected').value = rataSelected;

                                // aggiungo classe per background
                                var stepRate2 = document.querySelectorAll('.stepRata');
                                stepRate2.forEach(function (item2) {
                                    if (item2.classList.contains('selected')) {
                                        item2.classList.remove('selected');
                                    }
                                });
                                e.target.classList.add('selected');

                                // calcolo rata
                                var importoFinanziamento = document.querySelector('.importoFinanziamentoValue').value;
                                var importoAnticipo = document.querySelector('.importoAnticipo').value;

                                // controllo che l'anticipo non sia maggiore dell'importo auto
                                if (Number(importoAnticipo) > Number(importoFinanziamento)) {
                                    console.log('errore importoAnticipo');
                                } else {
                                    // se ho valorizzato importoFinanziamento, tan e selezionato rata posso calcolare la rata
                                    // controllo anche che l'anticipo non sia maggiore dell'importo auto
                                    if (Number(importoAnticipo) < Number(importoFinanziamento) && Number(importoFinanziamento) > 0 && Number(tanFinanziamento) > 0 && rataSelected != "" && Number(rataSelected) > 0) {
                                        var rataFinanziamento = calcolaRata(Number(importoFinanziamento), Number(importoAnticipo), Number(tanFinanziamento), Number(rataSelected));
                                        document.querySelector('.rataFinale').innerHTML = '€ ' + rataFinanziamento;
                                    }
                                }


                            });
                        });
                    }

                    // inizializzo evento change su campo Anticipo (calcolaRata)
                    var anticipoRata = document.querySelector('.importoAnticipo');
                    if (anticipoRata) {
                        anticipoRata.addEventListener('keyup', function (e) {
                            var importoAnticipo = e.target.value;

                            // calcolo rata
                            var importoFinanziamento2 = document.querySelector('.importoFinanziamentoValue').value;
                            var rataSelected2 = document.querySelector('.rataSelected').value;

                            // se ho valorizzato importoFinanziamento, tan e selezionato rata posso calcolare la rata.
                            // controllo anche che l'anticipo non sia maggiore dell'importo auto
                            if (Number(importoAnticipo) < Number(importoFinanziamento2) && Number(importoFinanziamento2) > 0 && Number(tanFinanziamento) > 0 && rataSelected2 != "" && Number(rataSelected2) > 0) {
                                var rataFinanziamento = calcolaRata(Number(importoFinanziamento2), Number(importoAnticipo), Number(tanFinanziamento), Number(rataSelected2));
                                document.querySelector('.rataFinale').innerHTML = '€ ' + rataFinanziamento;
                            }
                        });
                    }
                }

                // evento facetwp-refresh (reinizializzo scripts) 
                document.addEventListener('facetwp-refresh', function () {
                    if (FWP.loaded) {
                        FWP.setHash();
                        console.log('refreshato facetwp');

                        // riattivo scripts calcolo rata
                        setTimeout(function () {
                            initializeCalcoloRata();
                        }, 3000);
                    }
                });

                var chiudiPopupCalcolaRata = document.querySelector('#calcolaRataPopup .btn');
                if (chiudiPopupCalcolaRata) {
                    chiudiPopupCalcolaRata.addEventListener('click', function () {
                        $.magnificPopup.close();
                    });
                }

                // DOM READY --> attivo calcolo rata
                initializeCalcoloRata();

                // Popup banner in homepage
                var showPopupBannerHome = document.querySelector('#showPopupBannerHome');
                if (showPopupBannerHome && showPopupBannerHome.value == "1") {
                    setTimeout(function() {
                        $.magnificPopup.open({
                            items: {
                                src: '#bannerHomepage' 
                            },
                            type: 'inline',
                            midClick: false,
                            closeBtnInside: true,
                            closeOnBgClick: false,
                            closeOnContentClick: false,
                            callbacks: {
                                markupParse: function(template, values, item) {
                                    template.find('iframe').addClass('homePopupClass');
                                },
                                open: function (e) {
                                    setTimeout(function() {
                                        $.magnificPopup.close();
                                    }, 10000);
                                },
                             }
                        });
                    }, 500);
                    
                }

                // popup wishlist no login
                $('.wishlistNoLogin').magnificPopup({
                    type: 'inline',
                    midClick: true,
                    mainClass: 'popupGenerico',
                    closeBtnInside: false,
                    closeOnBgClick: false,
                    callbacks: {
                        open: function (e) {
                            // Will fire when this exact popup is opened
                            // this - is Magnific Popup object
                        },
                        close: function () {
                            // Will fire when popup is closed
                        }
                        // e.t.c.
                    }
                });

                // Wishlist no login -> chiudi popup
                var wishlistNoLoginPopup = document.querySelector('#wishlistNoLoginPopup .bottom span');
                if (wishlistNoLoginPopup) {
                    wishlistNoLoginPopup.addEventListener('click', function () {
                        $.magnificPopup.close();
                    });
                }


                // popup aggiungi auto a wishlist
                var addToWishlistElements = document.querySelectorAll('.addToWishlist');
                addToWishlistElements.forEach(function (item) {
                    var thisElement = $(item);

                    thisElement.magnificPopup({
                        type: 'inline',
                        midClick: true,
                        mainClass: 'popupGenerico',
                        closeBtnInside: false,
                        closeOnBgClick: false,
                        callbacks: {
                            open: function (e) {
                                // recupero il nonce
                                var nonce = document.querySelector('#nonce').value;
                                var userId = document.querySelector('#currentUserId').value;
                                var userName = document.querySelector('#currentUserName').value;
                                var productId = item.querySelector('.productId').value;
                                var productName = item.querySelector('.productName').value;

                                // aggiorno popup con titolo prodotto
                                var productTitlePopup = document.querySelector('#popupProductName');
                                productTitlePopup.innerHTML = productName;
                                var productOk = document.querySelector('#popupProductOk');
                                productOk.classList.remove('visible');
                                var productKo = document.querySelector('#popupProductKo');
                                productKo.classList.remove('visible');

                                // preloader
                                var preloaderPopup = document.querySelector('#addToWishlistPopup .lds-roller');
                                if (preloaderPopup.classList.contains('hidden')) {
                                    preloaderPopup.classList.remove('hidden');
                                }

                                // prima di inserire il prodotto controllo che non sia già stato inserito
                                // es. http://victoria.local/wp-json/wp/v2/wishlist/?meta_query=1&wishlist_idutente=4&wishlist_idprodotto=159
                                if (nonce && userId && productId) {
                                    axios({
                                        method: "get",
                                        url: state.restUrl + "wp/v2/wishlist/?meta_query=1&wishlist_idutente=" + userId + "&wishlist_idprodotto=" + productId,
                                        headers: {
                                            "X-WP-Nonce": nonce
                                        }
                                    })
                                        .then(function (response) {
                                            //console.log(response, response.status, response.data.length);
                                            if (200 === response.status) {

                                                // se è vuoto vuol dire che non ho già inserito il prodotto nella wishlist
                                                if (response.data.length === 0) {

                                                    // aggiungo il record
                                                    var postData = {
                                                        title: userName + '_' + productId,
                                                        acf: {
                                                            wishlist_idutente: userId,
                                                            wishlist_idprodotto: [
                                                                productId
                                                            ]
                                                        },
                                                        status: "publish"
                                                    };

                                                    axios({
                                                        method: "post",
                                                        url: state.restUrl + "wp/v2/wishlist",
                                                        data: postData,
                                                        headers: {
                                                            "Content-Type": "application/json",
                                                            "X-WP-Nonce": nonce
                                                        }
                                                    })
                                                        .then(function (response) {
                                                            // nascondo preloader
                                                            preloaderPopup.classList.add('hidden');

                                                            // aggiorno popup con messaggio "mezzo inserito nella wishlist"
                                                            if (productKo.classList.contains('visible')) {
                                                                productKo.classList.remove('visible');
                                                            }
                                                            productOk.classList.add('visible');
                                                        })
                                                        .catch(function (error) {
                                                            console.error(error);
                                                        });

                                                } else {
                                                    // nascondo preloader
                                                    preloaderPopup.classList.add('hidden');

                                                    // aggiorno popup con messaggio "già inserito nella wishlist"
                                                    if (productOk.classList.contains('visible')) {
                                                        productOk.classList.remove('visible');
                                                    }
                                                    productKo.classList.add('visible');
                                                }
                                            }
                                        })
                                        .catch(function (error) {
                                            console.error(error);
                                        });
                                }
                            },
                            close: function () {

                            }
                        }
                    });
                });

                // Add to Wishlist -> chiudi popup
                var addTowishlistClosePopup = document.querySelector('#addToWishlistPopup .bottom span');
                if (addTowishlistClosePopup) {
                    addTowishlistClosePopup.addEventListener('click', function () {
                        $.magnificPopup.close();
                    });
                }

                // popup rimuovi auto da wishlist
                var removeFromWishlistElements = document.querySelectorAll('.removeFromWishlist');
                removeFromWishlistElements.forEach(function (item) {
                    var thisElement = $(item);

                    thisElement.magnificPopup({
                        type: 'inline',
                        midClick: true,
                        mainClass: 'popupGenerico',
                        closeBtnInside: false,
                        closeOnBgClick: false,
                        callbacks: {
                            open: function (e) {
                                // recupero il nonce
                                var nonce = document.querySelector('#nonce').value;
                                var userId = document.querySelector('#currentUserId').value;
                                var userName = document.querySelector('#currentUserName').value;
                                var wishlistId = item.querySelector('.wishlistId').value;
                                var productId = item.querySelector('.productId').value;
                                var productName = item.querySelector('.productName').value;

                                // aggiorno popup con titolo prodotto
                                var productTitlePopup = document.querySelector('#popupProductName2');
                                productTitlePopup.innerHTML = productName;
                                var productOk = document.querySelector('#popupProductOk2');
                                productOk.classList.remove('visible');
                                var productKo = document.querySelector('#popupProductKo2');
                                productKo.classList.remove('visible');

                                // preloader
                                var preloaderPopup = document.querySelector('#removeFromWishlistPopup .lds-roller');
                                if (preloaderPopup.classList.contains('hidden')) {
                                    preloaderPopup.classList.remove('hidden');
                                }

                                // prima di rimuovere il prodotto controllo che esista
                                // es. http://victoria.local/wp-json/wp/v2/wishlist/?meta_query=1&wishlist_idutente=4&wishlist_idprodotto=159
                                if (nonce && userId && productId) {
                                    axios({
                                        method: "get",
                                        url: state.restUrl + "wp/v2/wishlist/?meta_query=1&wishlist_idutente=" + userId + "&wishlist_idprodotto=" + productId,
                                        headers: {
                                            "X-WP-Nonce": nonce
                                        }
                                    })
                                        .then(function (response) {
                                            //console.log(response, response.status, response.data.length);
                                            if (200 === response.status) {

                                                // se non è vuoto vuol dire che esiste e posso rimuoverlo
                                                if (response.data.length > 0) {

                                                    // rimuovo il record
                                                    axios({
                                                        method: "delete",
                                                        url: state.restUrl + "wp/v2/wishlist/" + wishlistId,
                                                        headers: {
                                                            "Content-Type": "application/json",
                                                            "X-WP-Nonce": nonce
                                                        }
                                                    })
                                                        .then(function (response) {
                                                            // nascondo preloader
                                                            preloaderPopup.classList.add('hidden');

                                                            // aggiorno popup con messaggio "mezzo rimosso dalla wishlist"
                                                            if (productKo.classList.contains('visible')) {
                                                                productKo.classList.remove('visible');
                                                            }
                                                            productOk.classList.add('visible');

                                                            // rimuovo mezzo dall'elenco
                                                            var elementToRemove = document.querySelector('#mezzo_' + wishlistId);
                                                            if (elementToRemove) {
                                                                elementToRemove.remove();
                                                            }

                                                            // mostro messaggio wishlist vuota (solo se non ho più elementi)
                                                            var totalElements = document.querySelectorAll('.box_auto_elenco');
                                                            console.log('ciao', totalElements);
                                                            var wishlistVuota = document.querySelector('.viaAjax');
                                                            if (wishlistVuota && totalElements.length == 0) {
                                                                wishlistVuota.classList.add('visible');
                                                            }
                                                        })
                                                        .catch(function (error) {
                                                            console.error(error);
                                                        });

                                                } else {
                                                    // nascondo preloader
                                                    preloaderPopup.classList.add('hidden');

                                                    // aggiorno popup con messaggio "errore rimozione dalla wishlist"
                                                    if (productOk.classList.contains('visible')) {
                                                        productOk.classList.remove('visible');
                                                    }
                                                    productKo.classList.add('visible');
                                                }
                                            }
                                        })
                                        .catch(function (error) {
                                            console.error(error);
                                        });
                                }
                            },
                            close: function () {

                            }
                        }
                    });
                });

                // Remove from Wishlist -> chiudi popup
                var removeFromwishlistClosePopup = document.querySelector('#removeFromWishlistPopup .bottom span');
                if (removeFromwishlistClosePopup) {
                    removeFromwishlistClosePopup.addEventListener('click', function () {
                        $.magnificPopup.close();
                    });
                }

                /**
                 * AUTENTICAZIONE PER REST API
                 */
                // Set state object with values that are changed programatically
                var state = {
                    loggedIn: false,
                    restUrl: "/wp-json/",
                    token: "wp-token",
                };

                function setState(toSet, newValue) {
                    state[toSet] = newValue;
                }

                // Controllo se l'utente è già autenticato o devo inizializzare il login
                if (cookie.get(state.token) === undefined) {
                    //logout();
                    //initLogin();
                } else {
                    //login();
                    //initLogout();
                }

                // setto variabile loggedIn a false
                function logout() {
                    setState("loggedIn", false);
                    console.log(state);
                }

                // setto variabile loggedIn a false
                function login() {
                    setState("loggedIn", true);
                    console.log(state);
                }

                // processo di login
                function initLogin() {
                    // recupero form del login
                    var formLogin = document.querySelector('#gform_0');
                    if (formLogin) {
                        // creo listener sul submit del form
                        formLogin.addEventListener('submit', function (e) {
                            e.preventDefault();

                            var creds = {
                                username: document.querySelector('#input_1').value,
                                password: document.querySelector('#input_2').value
                            }; // 1

                            /* var creds = {
                                username: 'system',
                                password: 'Archi15media',
                            };  */

                            if (creds.username !== "" && creds.password !== "") {
                                // Eseguo request per autenticarmi sulle REST API di Wordpress
                                axios({
                                    method: "post",
                                    url: state.restUrl + "api/v1/token",
                                    data: 'username=' + creds.username + '&password=' + creds.password,
                                    headers: {
                                        "Content-Type": "application/x-www-form-urlencoded"
                                    }
                                }) // 2
                                    .then(function (response) {
                                        console.log(response, response.status);
                                        if (200 === response.status) {
                                            console.log(response.data);
                                            cookie.set(state.token, response.data.jwt_token, {
                                                expires: 1,
                                                secure: false,
                                                path: '/',
                                            }); // 3

                                            if (cookie.get('wp-token') !== undefined) {
                                                // faccio il submit del form
                                                formLogin.submit();
                                            }

                                        } else {
                                            // Eseguito quando ricevo una reponse con status != 200 
                                            alert("Login failed, please check credentials and try again!");
                                        }
                                    }) // 5
                                    .catch(function (error) {
                                        // Loggo l'errore attuale
                                        console.error(error);

                                        // eseguo comunque il submit per mostrare l'errore di Wordpress all'utente
                                        formLogin.submit();
                                    });
                            }
                        });
                    }
                }

                // processo di logout
                function initLogout() {
                    var logoutLink = document.querySelector('.logout a');

                    if (logoutLink) {
                        logoutLink.addEventListener('click', function (e) {
                            e.preventDefault();
                            cookie.removeSpecific(state.token, {
                                expires: 1,
                                secure: false,
                                path: '/',
                            });

                            logout();

                            // eseguo redirect per logout di Wordpress
                            var logoutUrl = e.target.href;
                            document.location = logoutUrl;
                        });
                    }
                }

                /**
                 * FINE AUTENTICAZIONE
                 */

            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },

        // Page template Homepage
        'page_template_homepage': {
            init: function () {

                //var path3 = getBBox(document.querySelector("#mask_intro_path5"));
                //console.log(path3);

                // firefox fix
                var userAgentString = navigator.userAgent;
                var firefoxAgent = userAgentString.indexOf("Firefox") > -1;

                // focus search product
                var searchInput = document.querySelector('.searchProductForm input');
                var searchInputLabel = document.querySelector('.searchProductForm .gfield_label');
                if (searchInput) {
                    searchInput.addEventListener('focusin', function (e) {
                        searchInputLabel.style.cssText = 'top: 0; font-size: 1.2rem';
                        e.target.classList.add('focused');
                    });

                    searchInput.addEventListener('focusout', function (e) {
                        searchInputLabel.style.cssText = '';
                        e.target.classList.remove('focused');
                    });
                }

                // Slider homepage (header)
                var homepageSlider = $('.sliderHomepage');

                homepageSlider.slick({
                    arrows: false,
                    autoplay: true,
                    autoplaySpeed: 4000,
                    infinite: true,
                    pauseOnHover: false,
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    rows: 0, // remove div https://github.com/kenwheeler/slick/issues/3110
                });

                // Slider homepage (header)
                var homepageSliderUsato = $('.sliderUsatoHomepage');

                homepageSliderUsato.slick({
                    arrows: true,
                    autoplay: false,
                    autoplaySpeed: 4000,
                    infinite: false,
                    pauseOnHover: true,
                    dots: false,
                    slidesToShow: 2.5,
                    slidesToScroll: 1,
                    responsive: [
                        {
                            breakpoint: 1600,
                            settings: {
                                slidesToShow: 2.5,
                                slidesToScroll: 1,
                            }
                        },
                        {
                            breakpoint: 1400,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 1,
                            }
                        },
                        {
                            breakpoint: 1200,
                            settings: {
                                slidesToShow: 1.5,
                                slidesToScroll: 1,
                            }
                        },
                        {
                            breakpoint: 1080,
                            settings: {
                                slidesToShow: 2.5,
                                slidesToScroll: 1,
                            }
                        },
                        {
                            breakpoint: 820,
                            settings: {
                                slidesToShow: 1.8,
                                slidesToScroll: 1,
                            }
                        },
                        {
                            breakpoint: 640,
                            settings: {
                                slidesToShow: 1.4,
                                slidesToScroll: 1,
                            }
                        },
                        {
                            breakpoint: 480,
                            settings: {
                                slidesToShow: 1.2,
                                slidesToScroll: 1,
                            }
                        },
                    ],
                    rows: 0,
                });

                var totalSlideUsato = $('.sliderUsatoHomepage .slick-slide:not(.slick-cloned)').length - 1;

                // Cambio slide usato
                $('.sliderUsatoHomepage').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
                    console.log(nextSlide, totalSlideUsato - 1);

                    var prevArrow = document.querySelector('.sliderUsatoHomepage .slick-prev');
                    var nextArrow = document.querySelector('.sliderUsatoHomepage .slick-next');
                    var boxInfos = document.querySelectorAll('.box_info_usato article');

                    // disabilito arrow se arrivo alla fine o sono all'inizio
                    if (nextSlide > 0) {
                        if (prevArrow.classList.contains('disabled')) {
                            prevArrow.classList.remove('disabled');
                        }
                        prevArrow.classList.add('enabled');
                    } else {
                        if (prevArrow.classList.contains('enabled')) {
                            prevArrow.classList.remove('enabled');
                        }
                        prevArrow.classList.add('disabled');
                    }

                    if (nextSlide == totalSlideUsato - 1) {
                        if (nextArrow.classList.contains('enabled')) {
                            nextArrow.classList.remove('enabled');
                        }
                        nextArrow.classList.add('disabled');
                    } else {
                        if (nextArrow.classList.contains('disabled')) {
                            nextArrow.classList.remove('disabled');
                        }
                        nextArrow.classList.add('enabled');
                    }

                    // mostro il box info corretto
                    boxInfos.forEach(function (item) {
                        if (item.classList.contains('selected')) {
                            item.classList.remove('selected');
                        }
                    });
                    var boxInfoToShow = document.querySelector('#usato_' + nextSlide);
                    boxInfoToShow.classList.add('selected');

                });


            },
            finalize: function () {
                // JavaScript to be fired, after page specific JS is fired
                //gsap.registerPlugin(CSSRulePlugin);
            }
        },

        // Page template Nuovo
        'page_template_nuovo': {
            init: function () {

                // Slider modelli
                var sliderModelli = $('.sliderModelli');

                sliderModelli.slick({
                    arrows: false,
                    autoplay: true,
                    autoplaySpeed: 2000,
                    infinite: false,
                    pauseOnHover: true,
                    dots: true,
                    slidesToShow: 4.2,
                    slidesToScroll: 1,
                    responsive: [
                        {
                            breakpoint: 1900,
                            settings: {
                                slidesToShow: 4.2,
                                slidesToScroll: 1,
                            }
                        },
                        {
                            breakpoint: 1700,
                            settings: {
                                slidesToShow: 3.5,
                                slidesToScroll: 1,
                            }
                        },
                        {
                            breakpoint: 1400,
                            settings: {
                                slidesToShow: 2.8,
                                slidesToScroll: 1,
                            }
                        },
                        {
                            breakpoint: 1000,
                            settings: {
                                slidesToShow: 1.8,
                                slidesToScroll: 1,
                            }
                        },
                        {
                            breakpoint: 600,
                            settings: {
                                slidesToShow: 1.3,
                                slidesToScroll: 1,
                            }
                        }
                    ],
                    rows: 0,
                });

                var totalSlideModelliDots = $('.slick-dots li').length;

                // Torno indietro alla fine dello slide
                $('.sliderModelli').on('afterChange', function (event, slick, currentSlide, nextSlide) {

                    if (currentSlide == totalSlideModelliDots - 1) {
                        $('.sliderModelli').slick('slickGoTo', 0);
                    }

                });

            },
            finalize: function () {
                // JavaScript to be fired, after page specific JS is fired
                //gsap.registerPlugin(CSSRulePlugin);
            }
        },

        // Page template Usato
        'page_template_usato': {
            init: function () {

                // Slider brands
                //var sliderBrands = $('.sliderBrands');

                /* sliderBrands.slick({
                    arrows: false,
                    autoplay: true,
                    autoplaySpeed: 2000,
                    infinite: false,
                    pauseOnHover: true,
                    dots: false,
                    variableWidth: true,
                    slidesToShow: 9,
                    slidesToScroll: 1,
                    responsive: [
                        {
                            breakpoint: 1900,
                            settings: {
                                slidesToShow: 9,
                                slidesToScroll: 1,
                            }
                        },
                        {
                            breakpoint: 1700,
                            settings: {
                                slidesToShow: 8,
                                slidesToScroll: 1,
                            }
                        },
                        {
                            breakpoint: 1400,
                            settings: {
                                slidesToShow: 7,
                                slidesToScroll: 1,
                            }
                        },
                        {
                            breakpoint: 1000,
                            settings: {
                                slidesToShow: 5,
                                slidesToScroll: 1,
                            }
                        },
                        {
                            breakpoint: 600,
                            settings: {
                                slidesToShow: 4,
                                slidesToScroll: 1,
                            }
                        },
                        {
                            breakpoint: 480,
                            settings: {
                                slidesToShow: 2.5,
                                slidesToScroll: 1,
                            }
                        }
                    ],
                    rows: 0,
                }); */

                //var totalSlideBrandsDots = $('.slick-dots li').length;

                // Torno indietro alla fine dello slide
                /* $('.sliderBrands').on('afterChange', function (event, slick, currentSlide, nextSlide) {

                    if (currentSlide == totalSlideBrandsDots - 1) {
                        $('.sliderBrands').slick('slickGoTo', 0);
                    }

                }); */

                // mostra / nascondi filtri
                var showFilters = document.querySelector('.showFilters .btn--filtra');
                var elencoFiltri = document.querySelector('.elenco_filtri');
                if (showFilters) {
                    showFilters.addEventListener('click', function (e) {
                        console.log(elencoFiltri);
                        if (elencoFiltri) {
                            elencoFiltri.classList.toggle('active');
                        }
                    });
                }

            },
            finalize: function () {
                // JavaScript to be fired, after page specific JS is fired
                //gsap.registerPlugin(CSSRulePlugin);
            }
        },

        // Page template Modello
        'single_model': {
            init: function () {

                // Slider caratteristiche
                var sliderCaratteristiche = $('.sliderCaratteristiche');

                sliderCaratteristiche.slick({
                    arrows: true,
                    autoplay: false,
                    autoplaySpeed: 2000,
                    infinite: false,
                    pauseOnHover: true,
                    dots: false,
                    adaptiveHeight: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    prevArrow: $(".pp2"),
                    nextArrow: $(".nn2"),
                    rows: 0,
                });

                var totalSlideCaratteristiche = $('.sliderCaratteristiche .slick-slide:not(.slick-cloned)').length;

                // Torno indietro alla fine dello slide
                $('.sliderCaratteristiche').on('beforeChange', function (event, slick, currentSlide, nextSlide) {

                    var prevArrow = document.querySelectorAll('.sliderCaratteristiche .pp2');
                    var nextArrow = document.querySelectorAll('.sliderCaratteristiche .nn2');

                    // disabilito arrow se arrivo alla fine o sono all'inizio
                    if (nextSlide > 0) {
                        prevArrow.forEach(function (arrow) {
                            if (arrow.classList.contains('disabled')) {
                                arrow.classList.remove('disabled');
                            }

                            arrow.classList.add('enabled');
                        });

                    } else {
                        prevArrow.forEach(function (arrow) {
                            if (arrow.classList.contains('enabled')) {
                                arrow.classList.remove('enabled');
                            }

                            arrow.classList.add('disabled');
                        });
                    }

                    if (nextSlide == totalSlideCaratteristiche - 1) {
                        nextArrow.forEach(function (arrow) {
                            if (arrow.classList.contains('enabled')) {
                                arrow.classList.remove('enabled');
                            }

                            arrow.classList.add('disabled');
                        });
                    } else {
                        nextArrow.forEach(function (arrow) {
                            if (arrow.classList.contains('disabled')) {
                                arrow.classList.remove('disabled');
                            }

                            arrow.classList.add('enabled');
                        });
                    }
                });

            },
            finalize: function () {
                // JavaScript to be fired, after page specific JS is fired
                //gsap.registerPlugin(CSSRulePlugin);
            }
        },

        // Page template Prodotto (usato, km0, nuovo)
        'single_product': {
            init: function () {



            },
            finalize: function () {
                // JavaScript to be fired, after page specific JS is fired
                //gsap.registerPlugin(CSSRulePlugin);
            }
        },

        // Page template Assistenza
        'page_template_assistenza': {
            init: function () {
                var assistenzaSelect = document.querySelector('.selectAssistenza select');

                if (assistenzaSelect) {
                    assistenzaSelect.addEventListener('change', function (e) {
                        var elementSelected = e.target.value.replaceAll(' ', '_');
                        console.log(elementSelected);
                        var targetElementScrollTo = document.getElementById('blocco_' + elementSelected);
                        if (targetElementScrollTo) {
                            targetElementScrollTo.scrollIntoView();
                        }
                    });
                }

            },
            finalize: function () {
                // JavaScript to be fired, after page specific JS is fired

            }
        },

        // Page template Showrooms
        'page_template_showroom': {
            init: function () {
                // Slider sedi
                /**
                 *         prevArrow: $(itemToSlide).eq(1).siblings('.pp2').first(),
                           nextArrow: $(itemToSlide).eq(1).siblings('.nn2').first(),
                 */

                var sliderSediArray = document.querySelectorAll('.sede_gallery');
                if (sliderSediArray) {
                    sliderSediArray.forEach(function (item, key) {
                        var idGallery = item.id;
                        var itemToSlide = $('#' + idGallery);
                        itemToSlide.slick({
                            arrows: true,
                            autoplay: false,
                            infinite: false,
                            dots: false,
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            prevArrow: $(".pp2_" + key),
                            nextArrow: $(".nn2_" + key),
                            rows: 0,
                        });

                        var totalSlides = $('#' + idGallery + ' .slick-slide:not(.slick-cloned)').length;

                        // Torno indietro alla fine dello slide
                        $('#' + idGallery).on('beforeChange', function (event, slick, currentSlide, nextSlide) {

                            var prevArrow = document.querySelector(".pp2_" + key);
                            var nextArrow = document.querySelector(".nn2_" + key);

                            // disabilito arrow se arrivo alla fine o sono all'inizio
                            if (nextSlide > 0) {
                                if (prevArrow.classList.contains('disabled')) {
                                    prevArrow.classList.remove('disabled');
                                }

                                prevArrow.classList.add('enabled');

                            } else {
                                if (prevArrow.classList.contains('enabled')) {
                                    prevArrow.classList.remove('enabled');
                                }

                                prevArrow.classList.add('disabled');
                            }

                            if (nextSlide == totalSlides - 1) {
                                if (nextArrow.classList.contains('enabled')) {
                                    nextArrow.classList.remove('enabled');
                                }

                                nextArrow.classList.add('disabled');
                            } else {
                                if (nextArrow.classList.contains('disabled')) {
                                    nextArrow.classList.remove('disabled');
                                }

                                nextArrow.classList.add('enabled');
                            }
                        });
                    });
                }

            },
            finalize: function () {
                // JavaScript to be fired, after page specific JS is fired

            }
        },


    };// Sage

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };// UTIL

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
